import React, { Suspense } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Routes, Route } from "react-router-dom";
import routes from "./config";
const Header =  React.lazy(() => import("../components/Header/Header"));
const Home  = React.lazy(() => import("../pages/Home/Home"));
const Footer = React.lazy(() =>  import("../components/Footer/Footer"));

const Router = () => {
  return (
    <Suspense fallback={null}>
      <AnchorLink href="#totop">
        <button className="fixed myBtn cursor-pointer flex p-1.5 bg-blue-200 rounded-xl hover:rounded-3xl hover:bg-blue-600 transition-all duration-300 text-white">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="arrow-alt-circle-up"
            className="w-5 h-5"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M256 504c137 0 248-111 248-248S393 8 256 8 8 119 8 256s111 248 248 248zm0-448c110.5 0 200 89.5 200 200s-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56zm20 328h-40c-6.6 0-12-5.4-12-12V256h-67c-10.7 0-16-12.9-8.5-20.5l99-99c4.7-4.7 12.3-4.7 17 0l99 99c7.6 7.6 2.2 20.5-8.5 20.5h-67v116c0 6.6-5.4 12-12 12z"
            ></path>
          </svg>
        </button>
        </AnchorLink>
      <section className="bg-white">
        <div id="totop">
        <Header />
        </div>
        <Routes>
          {routes.map((routeItem) => {
            return (
              <Route
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact}
                element={<Home />}
              />
            );
          })}
        </Routes>
      </section>
      {/* <br/><br/><br/><br/><br/><br/> */}
      <Footer />
    </Suspense>
  );
};

export default Router;
